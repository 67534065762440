<template>
	<v-container fluid style="padding:0px;" :class="displaySizeClass">
		<div style="width:100%; height:128px; margin-top:-128px; background-color:gray;" v-if="isSmallScreen!='Mobile'"></div>
        <v-card 
            class="mx-auto"
            flat
            v-if="!reveal && !signUp"
        >
            <v-card-title style="justify-content:center;">
                <h1 style="word-break: break-word;">{{loginLabel[languageId]}}</h1>
            </v-card-title>
            <v-card-text style="max-width:300px; min-height:200px;" class="mx-auto" >
                <v-row >
                    <v-col cols="12" style="padding:0px;" hidden>
                        <FacebookButton @logged="facebookLogin"></FacebookButton>
                    </v-col>
                    <v-col cols="12" style="padding:0px;">
                        <GoogleButton @logged="googleLogin"></GoogleButton>
                    </v-col>
                </v-row>
                <v-row class="mt-3">
                    <v-col cols="12">
                        <v-btn block color="primary" @click="reveal=true; socialRegister=false">{{loginLabel[languageId]}}</v-btn>
                    </v-col>
                    <v-col cols="12">
                        <v-btn block color="secondary" @click="guestAccess()">{{buyAsGuestLabel[languageId]}}</v-btn>
                    </v-col>
                </v-row>
                <v-row class="mt-3">
                    <v-col cols="12">
                        <v-btn block text color="primary" @click="signUpUser()">{{createAccountLabel[languageId]}}</v-btn>
                    </v-col>
                </v-row>
            </v-card-text>
        </v-card>
        <v-card
            v-else-if="reveal"
            class="transition-fast-in-fast-out v-card--reveal"
            style="height: 100%;"
            flat
        >
            <v-card-title style="justify-content:center;">
                <h1 style="word-break: break-word;">{{loginLabel[languageId]}}</h1>
            </v-card-title>

            <v-card-text style="max-width:500px;" class="pb-0 mx-auto">
                <v-form ref="form" >
                    <v-text-field @keydown="loginKeyPress($event)" type="text" :label="emailLabel[languageId]" v-model="form.email" :rules="emailRules[languageId]"></v-text-field>
                    <v-text-field @keydown="loginKeyPress($event)" :label="passwordLabel[languageId]" v-model="form.password" :append-icon="typePassword?'mdi-eye':'mdi-eye-off'" @click:append="typePassword=!typePassword" :type="typePassword?'password':'text'" :rules="passwordLoginRules[languageId]"></v-text-field>
                </v-form>
                <v-row >
                    <v-col cols="12">
                        <v-btn block text color="primary" @click="rememberPasswordDialog=true">{{rememberPasswordLabel[languageId]}}</v-btn>
                    </v-col>
                </v-row>
            </v-card-text>
            <v-card-actions style="max-width:500px; " class="mx-auto">
                <v-btn text color="secondary" @click="reveal = false" >
                    {{returnLabel[languageId]}}
                </v-btn>
                <v-spacer></v-spacer>
                <v-btn color="primary" @click="login('login')" >
                    {{continueLabel[languageId]}}
                </v-btn>
            </v-card-actions>
        </v-card>
        <v-card
            v-if="signUp"
            class="transition-fast-in-fast-out v-card--reveal"
            style="height: 100%;"
            flat
        >
            <v-card-title style="justify-content:center;">
                <h1 style="word-break: break-word;">{{createAccountLabel[languageId]}}</h1>
            </v-card-title>

            <v-card-text style="max-width:500px; " class="pb-0 mx-auto">
                <v-form ref="signUpForm">
                    <v-text-field  @keydown="registerKeyPress($event)" type="text" :label="nameLabel[languageId]" v-model="signUpForm.userName" :rules="userNameRules[languageId]"></v-text-field>
                    <v-text-field  @keydown="registerKeyPress($event)" type="text" :label="emailLabel[languageId]" v-model="signUpForm.email" :rules="emailRules[languageId]"></v-text-field>
                    <v-text-field  @keydown="registerKeyPress($event)" v-if="!socialRegister" :label="passwordLabel[languageId]" v-model="signUpForm.password" :append-icon="typePassword2?'mdi-eye':'mdi-eye-off'" @click:append="typePassword2=!typePassword2" :type="typePassword2?'password':'text'" :rules="passwordRules[languageId]"></v-text-field>
                </v-form>
            </v-card-text>
            <v-card-actions style="max-width:500px; " class="mx-auto">
                <v-btn text color="secondary" @click="signUp = false; reveal=false" >
                    {{returnLabel[languageId]}}
                </v-btn>
                <v-spacer></v-spacer>
                <v-btn color="primary" @click="login('create')" >
                    {{createAccountLabel[languageId]}}
                </v-btn>
            </v-card-actions>
        </v-card>
        <div class="text-center">
            <v-dialog v-model="rememberPasswordDialog" width="500" >
            <v-card>
                <v-card-title class="headline grey lighten-2">
                    {{rememberPasswordLabel[languageId]}}
                </v-card-title>
                <v-card-text class="pt-5">
                    <v-form ref="rememberForm">
                        <v-text-field type="text" :label="emailLabel[languageId]" v-model="rememberPasswordEmail" :rules="emailRules[languageId]"></v-text-field>
                    </v-form>
                </v-card-text>

                <v-divider></v-divider>

                <v-card-actions>
                    <v-btn color="secondary" text @click="rememberPasswordDialog = false" >
                        {{returnLabel[languageId]}}
                    </v-btn>
                    <v-spacer></v-spacer>
                    <v-btn color="primary" @click="rememberPassword" >
                        {{rememberPasswordLabel[languageId]}}
                    </v-btn>
                </v-card-actions>
            </v-card>
            </v-dialog>
        </div>
	</v-container>
</template>

<script>
	import { mapState, mapActions } from "vuex";
    import GoogleButton from './components/GoogleButton';
    import FacebookButton from './components/FacebookButton';
    import axios from "axios";
    
    const baseURL = process.env.VUE_APP_BASE_URL_API;
    const captchaKey = process.env.VUE_APP_CAPTCHA_KEY;

	export default {
		name: 'Login',
		components: { GoogleButton, FacebookButton },
		data: () => ({
			pageTitle:['Iniciar sesión Karlus Morales','Login Karlus Morales'],
			pageMetaTitle:['Iniciar sesión Karlus Morales','Login Karlus Morales'],
			pageDescription:['Página para inicar sesión en Karlus Morales','Login page to Karlus Morales'],
            pageKeywords:['Iniciar sesión','Login'],
            metaImage:'',
            buyAsGuestLabel:["Comprar como invitado", "Buy as guest"],
            rememberPasswordLabel:["Recordar contraseña","Remember password"],
            signUp:false,
            socialRegister:false,
            typePassword:true,
            typePassword2:true,
            userNameRules: [
				[
					v => !!v || 'El nombre es necesario.',
					// v => /^[a-zA-Z]+$/.test(v) || 'El nombre solo debe contener letras.',
				],
				[
					v => !!v || 'Name is required.',
					// v => /^[a-zA-Z]+$/.test(v) || 'Name requires only letters.',
				]
            ],
            emailRules: [
				[
					v => !!v || 'El email es necesario.',
					// v => /.+@.+\..+/.test(v) || 'El email debe tener un formato válido.',
					v => /^[a-zA-Z0-9._]+@[a-zA-Z0-9-]+\.[a-zA-Z0-9-.]+$/.test(v) || 'El email debe tener un formato válido.',
				],
				[
					v => !!v || 'Email is required.',
					// v => /.+@.+\..+/.test(v) || 'Email format not valid.',
					v => /^[a-zA-Z0-9._]+@[a-zA-Z0-9-]+\.[a-zA-Z0-9-.]+$/.test(v) || 'Email format not valid.',
				]
            ],
            passwordLoginRules: [
				[
					v => !!v || 'La contraseña es necesaria.',
				],
				[
					v => !!v || 'Password is required.',
				]
            ],
            passwordRules: [
                [
                    v => !!v || 'La contraseña es necesaria.',
                    v => v.length >= 6 || 'Se requieren por lo menos 6 caracteres.',
                    v => /\d/.test(v) || 'Se requiere un número.',
                    v => /[a-z]/.test(v) || 'Se requiere una minúscula.',
                    v => /[A-Z]/.test(v) || 'Se requiere una mayúscula.',
                    v => /[!@#$%^&*)(+=._-]/.test(v) || 'Se requiere un caracter especial.',
                ],
                [
                    v => !!v || 'Password is required.',
                    v => v.length >= 6 || 'At least 6 characters required.',
                    v => /\d/.test(v) || 'A number is required.',
                    v => /[a-z]/.test(v) || 'A lowercase is required.',
                    v => /[A-Z]/.test(v) || 'An uppercase is required.',
                    v => /[!@#$%^&*)(+=._-]/.test(v) || 'A special character is required.',
                ]
            ],
            reveal:false,
            form:{
                captcha:'',
                email:'',
                password:''
            },
            signUpForm:{
                userName:'',
                email:'',
                password:'',
                captcha:''
            },
            rememberPasswordDialog:false,
            rememberPasswordEmail:"",
        }),
		metaInfo(){
			return{
				title: this.pageTitle[this.languageId],
				meta: [
					{
					vmid: 'robots',
					name: 'robots',
					content: 'noindex, nofollow',
					},
					{
					vmid: 'title',
					name: 'title',
					content: this.pageMetaTitle[this.languageId],
					},
					{
					vmid: 'description',
					name: 'description',
					content: this.pageDescription[this.languageId],
					},
					{
					vmid: 'keywords',
					name: 'keywords',
					content: this.pageKeywords[this.languageId],
					},
					{
					vmid: 'og:title',
					property: 'og:title',
					content: this.pageMetaTitle[this.languageId],
					},
					{
					vmid: 'og:description',
					property: 'og:description',
					content: this.pageDescription[this.languageId],
					},
					{
					vmid: 'og:image',
					property: 'og:image',
					content: this.metaImage,
					},
					{
					vmid: 'og:url',
					property: 'og:url',
					content: `${window.location.href}`,
					},
					{
					vmid: 'twitter:title',
					name: 'twitter:title',
					content: this.pageMetaTitle[this.languageId],
					},
					{
					vmid: 'twitter:description',
					name: 'twitter:description',
					content: this.pageDescription[this.languageId],
					},
					{
					vmid: 'twitter:image',
					name: 'twitter:image',
					content: this.metaImage,
					},
					{
					vmid: 'twitter:url',
					name: 'twitter:url',
					content: `${window.location.href}`,
					},
					{
					vmid: 'twitter:card',
					name: 'twitter:card',
					content: 'summary',
					},
				]
			}
		},
        async mounted () {
            await this.loadRecaptcha(document, "script", "google-recaptcha");
		},
		beforeDestroy(){
			document.getElementsByClassName("grecaptcha-badge")[0].style.display="none";
		},
        computed:{
			...mapState("shoppingCart", 
                [
                    "app_token", "checkoutClick", "loginLabel", "createAccountLabel", "languageId", "returnLabel", "continueLabel", "nameLabel", "emailLabel", "passwordLabel",
                ]),
            isSmallScreen(){
				var screenType = "Desktop";
				if(this.displaySizeClass == 'mobile-size')
					screenType = "Mobile";
				if(this.displaySizeClass == 'tablet-size')
					screenType = "Tablet";
				return screenType
			},
			displaySizeClass(){
				var displayClass = "desktop-size";
				var windowWidth = this.widthSize;
				if(windowWidth <= 640)
					displayClass = "mobile-size";
				if(windowWidth >= 1024 && windowWidth <= 1440)
					displayClass = "tablet-size";
				return displayClass
			},
			widthSize(){
				var windowWidth = window.innerWidth;
				if(windowWidth < 320)
					windowWidth = 320
				if(windowWidth > 640 && windowWidth < 1024)
					windowWidth = 640
				if(windowWidth > 1440 && windowWidth < 1920)
					windowWidth = 1440
				if(windowWidth > 1920)
					windowWidth = 1920
				return windowWidth
			},
        },
		methods: { 
			...mapActions("shoppingCart", ["setMutation"]),

            async loadRecaptcha(d, s, id) {
                var js,
                    fjs = d.getElementsByTagName(s)[0];
                if (d.getElementById(id)) {
					d.getElementsByClassName("grecaptcha-badge")[0].style.display="block";
                    return;
                }
                js = d.createElement(s);
                js.id = id;
                js.src = "https://www.google.com/recaptcha/api.js?render="+captchaKey;
                fjs.parentNode.insertBefore(js, fjs);
            },

            facebookLogin(value){
                this.externalLogin('Facebook',value);
            },
            googleLogin(value){
                this.externalLogin('Google',value);
            },

            externalLogin(provider,id_token){
                window.grecaptcha.ready(() => {
                    window.grecaptcha.execute(captchaKey, {action: 'submit'})
                        .then((token) => {
                            this.externalLoginRequest(provider,id_token,token);
                        });
                })
            },

            externalLoginRequest(provider,id_token,token){
                axios.post(`${baseURL}Account/${provider}-login`, {Provider:provider, IdToken:id_token, Captcha:token})
                    .then(res => {
                        if(res.status == 200){
                            if(res.data.status == "Redirect"){
                                this.socialRegister = true;
                                this.signUp = true;
                                this.signUpForm.email = res.data.message.email;
                                this.signUpForm.provider = provider;
                                this.signUpForm.socialId = res.data.message.id;
                                this.external_id_token = id_token;
                                this.external_provider = provider;
                            }
                            if(res.data.status == "Token"){
                                
                                this.setMutation({with:res.data.message.token, property:'app_token'});
                                this.setMutation({with:false, property:'buyAsGuest'});
                                if(this.checkoutClick)
                                    this.$router.push('/checkout');
                                else
                                    this.$router.push('/');
                            }
                        }
                    })
                    .catch(error => { 
                        console.log(error);
                    });
            },
            signUpUser(){
                this.signUp = true;
            },
            login(type){
                if(type=="login"){
                    if(this.$refs.form.validate()){
                        window.grecaptcha.ready(() => {
                            window.grecaptcha.execute(captchaKey, {action: 'login'})
                                .then((token) => {
                                    this.form.captcha = token;
                                    this.signIn(this.form);
                                });
                        })
                    }
                }
                if(type=="create"){
                    if(this.$refs.signUpForm.validate()){
                        window.grecaptcha.ready(() => {
                            window.grecaptcha.execute(captchaKey, {action: 'submit'})
                                .then((token) => {
                                    this.signUpForm.captcha = token
                                    this.register(token);
                                });
                        })
                    }
                }
            },
            register(token){
                axios.post(`${baseURL}Account/Register`, this.signUpForm)
                    .then(res => {
                        if(res.data.status == "Success"){
                            var form = {
                                email:this.signUpForm.email,
                                password:this.signUpForm.password,
                                captcha: token
                            }
                            if(this.socialRegister){
                                window.grecaptcha.ready(() => {
                                    window.grecaptcha.execute(captchaKey, {action: 'login'})
                                        .then((token) => {
                                            this.externalLoginRequest(this.external_provider,this.external_id_token,token);
                                        });
                                })
                            }
                            else{
                                window.grecaptcha.ready(() => {
                                    window.grecaptcha.execute(captchaKey, {action: 'login'})
                                        .then((token) => {
                                            form.captcha = token;
                                            this.signIn(form);
                                        });
                                })
                            }
                        }
                    })
                    .catch(error => { 
                        console.log(error);
                    });
            },
            signIn(form){
                axios.post(`${baseURL}Account/Login`, form)
                    .then(res => {
                        if(res.status== 200){
                            this.setMutation({with:res.data.token, property:'app_token'});
                            this.setMutation({with:false, property:'buyAsGuest'});
                            if(this.checkoutClick)
                                this.$router.push('/checkout');
                            else
                                this.$router.push('/');
                        }
                    })
                    .catch(error => {
                        if(error.response.status == 401)
                            this.reveal = false;
                        console.log(error);
                    });
            },
            guestAccess(){
                window.grecaptcha.ready(() => {
                    window.grecaptcha.execute(captchaKey, {action: 'login'})
                        .then((token) => {
                            axios.post(`${baseURL}Account/guest-login`, {captcha:token})
                                .then(res => {
                                    if(res.status== 200){
                                        this.setMutation({with:res.data.token, property:'app_token'});
                                        this.setMutation({with:true, property:'buyAsGuest'});
                                        if(this.checkoutClick)
                                            this.$router.push('/checkout');
                                        else
                                            this.$router.push('/');
                                    }
                                })
                                .catch(error => { 
                                    console.log(error);
                                });
                        });
                })
            },

            rememberPassword(){
                if(this.$refs.rememberForm.validate()){
                    window.grecaptcha.ready(() => {
                        window.grecaptcha.execute(captchaKey, {action: 'submit'})
                            .then((token) => {
                                this.rememberPasswordRequest(token);
                            });
                    })
                }
            },
            rememberPasswordRequest(token){
                axios.post(`${baseURL}Account/send-remember-password`,{ email:this.rememberPasswordEmail, captcha:token })
                    .then((res) => {
                        if(res.status == 204){
                            this.rememberPasswordDialog = false;
                        }
                        if(res.status == 200){
                            this.rememberPasswordDialog = false;
                            this.$router.push('/reset-password');
                        }
                    })
                    .catch(error => { 
                        console.log(error);
                    });
            },

            loginKeyPress(event){
                if(event.key == 'Enter'){
                    this.login('login');
                }
                return null;
            },
            registerKeyPress(event){
                if(event.key == 'Enter'){
                    this.login('create');
                }
                return null;
            },

        },

	}
</script>
<style scoped>

.information {
  margin-top: 100px;
  margin: auto;
  display: flex;
  flex-direction: column;
}
.well {
  background-color: rgb(191, 238, 229);
  margin: auto;
  padding: 50px 50px;
  ;
  border-radius: 20px;
}
.login {
  width: 200px;
  margin: auto;
}
.list-item:first-child {
  margin: 0;
}
.list-item {
  display: flex;
  align-items: center;
  margin-top: 20px;
}
.button {
  margin: auto;
  border-radius:5px;
}

</style>